var pathname = location.pathname;
var pathArray = window.location.pathname.split( '/' );
var firstLevelLocation = pathArray[1];
var secondLevelLocation = pathArray[2];
var highlight;

$(function() { 

    // First Level
    if(pathname == "/")
        highlight = $('header h1 a');
    else {
        highlight = $('nav > ul > li a[href$="/' + firstLevelLocation + '/"]');
    }

    highlight.addClass('active');
});

$(function() {

    // Second Level
    highlight = $('.subnav li a[href$="/' + secondLevelLocation + '/"]');

    highlight.addClass('active');
});


    var windowWidth, windowHeight;

    windowWidth = $(window).width();
    $(window).resize(function(){
        windowWidth = $(window).width();
    });

    windowHeight = $(window).height();
    $(window).resize(function(){
        windowHeight = $(window).height();
    });


$.fn.exists = function(callback) {
  var args = [].slice.call(arguments, 1);

  if (this.length) {
    callback.call(this, args);
  }

  return this;
};




$(document).ready(function() {
    // Bust cache for reloading slides
    $.ajaxSetup({cache:false});

    // Handler
    $(".wow").on('click', '.wow-toggle', function() {
        // Grab the slides from the project via permalink
        var post = $(this).attr("href");
        var sel = post + ' #wow-slides';
        $(".wow-slides").html("Loading...");
        // Load in slides, launch slider, add toggle
        $(".wow-slides").load(sel, function() {
            $('.wow-slider').bxSlider({
                controls: true,
                pager: false,
                mode: 'fade'
            });
            $('.bx-wrapper, .close').addClass('full-screen-mode');
            $('.switch-full').on('click', function() {
                if ( $(this).is(':checked') ) {
                    $(this).parent().next().next().next('.after-hide').addClass('after-show');
                    $(this).parent().next('.form-switcher-view').addClass('is-active');
                } else {
                    $(this).parent().next().next().next('.after-hide').removeClass('after-show');
                    $(this).parent().next('.form-switcher-view').removeClass('is-active');
                }
            });
        });

        return false;
    });

    //toggle switch for before/wow mode
    $('.switch').on('click', function() {
        if ( !$(this).is(':checked') ) {
            // console.log('no check', $(this).parent().next('.before'));
            $(this).parent().next('.before').addClass('before-active');
        } else {
            // console.log('check', $(this).parent().next('.before'));
            $(this).parent().next('.before').removeClass('before-active');
        }
    });

    $('.switch-full').on('click', function() {
        //console.log('sf');
        if ( $(this).is(':checked') ) {
            $(this).parent().next().next().next('.after-hide').addClass('after-show');
            $(this).parent().next('.form-switcher-view').addClass('is-active');
        } else {
            $(this).parent().next().next().next('.after-hide').removeClass('after-show');
            $(this).parent().next('.form-switcher-view').removeClass('is-active');
        }
    });

    $('#types .interior-furnishings-jas-home a, #home_services li').html(function(_, html) {
       return html.split('JAS').join("<span class='uppercase'><br>JAS</span>");
    });

    $('#interior-furnishings-jas-home h1').html(function(_, html) {
       return html.split('JAS H').join("<span class='uppercase'>JAS H</span>");
    });

    $('.view-project').on('click', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        window.location.href = url;
    });

    $('#mobile_nav_button').click(function(){
        e.preventDefault();
        $('#mobile_nav').slideToggle('slow');
        return false;
    });

    $('body').on('click', '.close', function(e) {
        e.preventDefault();
        //console.log('cl');
        $('#maximage').fadeOut(800);
        $('.bx-wrapper, .close').removeClass('full-screen-mode');
        $('.close').hide();
        var largePhotoIndex = $('#maximage .mc-image[style*="opacity: 1"]').index();
        $('#photos').cycle(largePhotoIndex);

        $('#maximage .wrapper .mc-image').remove();
        
    });

    if ( $('.full-story').length) {
        $('article h1').addClass('has-story');
    }

    if($(window).width() < 480) {

		$('.photo img').each( function() {
			$(this).removeAttr('width');
		});
	}

    if (parseInt($(window).width()) > 480) {

        // Photo Slideshow
	    $('#photos').exists(function() {
            //console.log('start slideshow');
	    	//$('#photos').append('<div id="photos_nav">');
	        $('#photos').cycle({
	            fx:      'scrollHorz',
                mode: 'fade',
	            timeout:  0,
	            slideExpr: '.photo',
	            prev: '.slider-ctrl-prev',
	            next: '.slider-ctrl-next',
	            speed: 400,
	            fit: true,
	        });

	    });

    }


    if (parseInt($(window).width()) > 640) {



        // $('.single-projects .photo a').click( function () {
        //     console.log('yo!', $(this).data());
        //     var small = $(this).data('largeSrc');
        //     $('.photo a').each( function () {
        //         if(small == $(this).data('largeSrc')) {
        //             console.log('bo!', $(this).data());

        //             var data_src = $(this).attr('data-large-src');
        //             var img_alt = $(this).attr('alt');
        //             $('#maximage').append('<img src="' + data_src + '"  alt="' + img_alt + '" class="w-full object-cover" />');
        //             // $('.bxslider').append('<li><div class="form-switcher form-switcher-lg"><p class="before-label">before</p><input class="switch-full" type="checkbox" name="switcher-main" id="switcher-main-' + (index + 100) + '" checked="checked"><label class="switcher" for="switcher-main-' + (index + 100) + '"></label><p class="before-label">wow</p></div> <figure class="desaturate before-full" style="background: url('+ before_src +') no-repeat center center/contain, url(' + siteurl + '/img/bg-texture.jpg) repeat;"></figure><figure class="after-hide after-show" style="background: url(' + after_src + ') no-repeat center center; background-size: cover;"></figure></li>');
        //             // $('.bxslider').append('<li class="" style="background: url('+ data_src +') no-repeat center center; background-size: cover;"></li>');
        //         }
        //     });

        //     // $('#maximage').fadeIn(800);

        //     return false;
        // });

        
        $('.photo a').each(function(index) {
            // console.log('ind', $(this));

            if ( $(this).hasClass('before') ) {
                var before_src = $(this).parent().find('a:eq(0)').attr('data-large-src');
                var contain = $(this).parent().find('a:eq(0)').attr('data-contain');
                var after_src = $(this).parent().find('a:eq(1)').attr('data-large-src');
            } else if ( $(this).hasClass('after') ) {
                var after_src = $(this).attr('data-large-src');
            }
            // console.log('a s', after_src);
            // var img_alt = $(this).attr('alt');

            if (before_src != undefined) {
                if (contain == true) {
                    $('.bxslider').append('<li><div class="form-switcher form-switcher-lg"><p class="before-label">before</p><input class="switch-full" type="checkbox" name="switcher-main" id="switcher-main-' + (index + 100) + '" checked="checked"><label class="switcher" for="switcher-main-' + (index + 100) + '"></label><p class="before-label">wow</p></div> <figure class="desaturate before-full" style="background: url('+ before_src +') no-repeat center center/contain, url(' + siteurl + '/img/bg-texture.jpg) repeat;"></figure><figure class="after-hide after-show" style="background: url(' + after_src + ') no-repeat center center; background-size: cover;"></figure></li>');
                } else {
                    $('.bxslider').append('<li><div class="form-switcher form-switcher-lg"><p class="before-label">before</p><input class="switch-full" type="checkbox" name="switcher-main" id="switcher-main-' + (index + 100) + '" checked="checked"><label class="switcher" for="switcher-main-' + (index + 100) + '"></label><p class="before-label">wow</p></div> <figure class="desaturate before-full" style="background: url('+ before_src +') no-repeat center center; background-size: cover;"></figure><figure class="after-hide after-show" style="background: url(' + after_src + ') no-repeat center center; background-size: cover;"></figure></li>');
                }
            }

            if ($(this).hasClass('after') && !$(this).prev('a').hasClass('before') ) {
                // console.log('ddd');
                $('.bxslider').append('<li class="" style="background: url('+ after_src +') no-repeat center center; background-size: cover;"></li>');
            }
            // return false;
        });

        slider = $('.bxslider').bxSlider({
            controls: true,
            pager: false,
            mode: 'fade',
            // slideResize: true,
            // responsive: true,
        });

        $('#prev').on('click', function(e) {
            e.preventDefault();
            slider.goToPrevSlide();
        });

        $('#next').on('click', function(e) {
            e.preventDefault();
            slider.goToNextSlide();
        });

        $('.bx-prev').on('click', function() {
            $('#photos').cycle('prev');
        });

        $('.bx-next').on('click', function() {
            $('#photos').cycle('next');
        });

        $('.wow a').each(function() {
            $('.wow a').on('click', function(e){
                e.preventDefault();
                $(this).parent().next('.bx-wrapper').addClass('full-screen-mode');
            });
        });

        $('.wow a').on('click', function(e){
            e.preventDefault();
            $('.bx-wrapper').addClass('full-screen-mode');
        });

        $('.single-projects .photo a').on('click', function(){
            $('body').append('<a href="#" class="close ir full-screen-mode">Close</a>');
            // $('.close').addClass('full-screen-mode');
            $('.bxslider').parent().parent().addClass('full-screen-mode');

            var photoIndex = $(this).parent('.photo').index();
            //console.log('ind', photoIndex);

            $('.switch-full').on('click', function() {
                if ( !$(this).is(':checked') ) {
                    $(this).parent().next().next().removeClass('after-show');
                } else {
                    $(this).parent().next().next().addClass('after-show');
                }
            });

            //console.log('maximage', photoIndex, slider);
            slider.goToSlide(photoIndex);

	        $('#maximage .wrapper').maximage({
	        	cycleOptions: {
                    startingSlide: photoIndex,
	        		fx: 'fade',
	        		speed: 1000,
	        		timeout: 0,
	        		prev: '#mx_prev',
	        		next: '#mx_next'
	        	}
	    	});            
        });

        $('#maximage .wrapper').touchwipe({
            wipeLeft: function() {
                $('#maximage .wrapper').cycle('next');
            },
            wipeRight: function() {
                $('#maximage .wrapper').cycle('prev');
            }
        });

    }



    $( document ).on( 'keydown', function ( e ) {

        if ( $('.bx-wrapper').css('opacity') == 1 ) {

            if ( e.keyCode === 37 ) {
                if ($('#wow')) {
                    $('.bx-prev').click();
                } else {
                    $('#maximage #mx_prev').click();
                    $('#photos').cycle('prev');
                    slider.goToPrevSlide();
                }
            }

            if ( e.keyCode === 39 ) {
                if ($('#wow')) {
                    $('.bx-next').click();
                } else {
                    $('#maximage #mx_next').click();
                    $('#photos').cycle('next');
                    slider.goToNextSlide();
                }
            }
        }

         if ( e.keyCode === 27 ) {
            $('#maximage').fadeOut(800);
            $('.bx-wrapper, .close').removeClass('full-screen-mode');


            var largePhotoIndex = $('#maximage .mc-image[style*="opacity: 1"]').index();
            $('#photos').cycle(largePhotoIndex);

            $('#maximage .wrapper .mc-image').remove();

        }
    });


    $('#maximage .close').click( function () {
        $('#maximage').fadeOut(800);
        // console.log('close click');
        // $(this).fadeOut(300);
        // $('.close').removeClass('full-screen-mode');

        var largePhotoIndex = $('#maximage .mc-image[style*="opacity: 1"]').index();
        $('#photos').cycle(largePhotoIndex);
        $('#maximage .wrapper .mc-image').remove();

		var vimeoWrap = $('#video-wrap');
		vimeoWrap.html( vimeoWrap.html() );

        return false;
    });


    // $('#home #maximage').click( function () {
    //     $('#maximage').fadeOut(800, function(){
	// 		var vimeoWrap = $('#video-wrap');
	// 		vimeoWrap.html( vimeoWrap.html() );
    //     });



    //     return false;
    // });


    var maxTop = (windowHeight / 2) - 122;
    // $(window).resize(function(){
    //     var maxTop = (windowHeight / 2) - 122;
    //     //fix for bxslider not being responsive on project pages
    //     $('#photos').removeAttr('style');
    //     $('#photos').height($('#photos').find('.photo').height());
    // });

    if (windowWidth > 640) {
        setTimeout(function() {
            $('#photos').removeAttr('style');
            $('#photos').height($('#photos').find('.photo').height());
        }, 500);
    }

    $('#maximage #mx_next, #maximage #mx_prev').css('top', maxTop);


    $('#share .houzz').click( function () {
        var head = document.getElementsByTagName('head')[0], script=document.createElement('script');
        script.type='text/javascript';
        script.src='http://www.houzz.com/js/clipperBookmarklet.js?' + Math.floor(Math.random()*99999);
        head.appendChild(script);

        return false;
    });


    $('#share .link').toggle( function () {
        $(this).siblings('.link_field').fadeIn(400);
        return false;
    }, function () {
        $(this).siblings('.link_field').fadeOut(400);
        return false;
    });
    // Photo Slideshow Dot Nav


    var photos_width = $('#photos').width();
    var nav_left = (photos_width / 2) - ( $('#photos_nav').outerWidth() / 2 );
    $('#photos_nav').css('left', nav_left);
    $(window).resize(function() {
        var photos_width = $('#photos').width();
        var nav_left = (photos_width / 2) - ( $('#photos_nav').outerWidth() / 2 );
        $('#photos_nav').css('left', nav_left);
    });


	// rel="external"
	$('a[rel="external"]').click( function() {
		window.open( $(this).attr('href') );
		return false;
	});

	$('#home #video-link').click( function() {
	 	$(this).siblings('#maximage').fadeIn();
        $(this).siblings('#maximage').find('.close').show();
	 	return false;
	});




	//Homepage Hero
    $('#hero .slide').each( function() {
        var img = $(this).attr('data-img');
        $(this).backstretch(img);
    });

   $('#hero .slides').exists(function() {


	    $('#hero').append('<div id="hero_nav">');
	    $('#hero .slides').cycle({
	        fx:      'scrollHorz',
	        timeout: 5000,
	        slideExpr: '.slide',
	        prev: '#prev-home',
	        next: '#next-home',
	        pager:  '#hero_nav',
	        speed: 800,
	        slideResize: false
	    });
    });


    var hero_nav_left = (windowWidth / 2) - ( $('#hero_nav').outerWidth() / 2 );
    $('#hero_nav').css('left', hero_nav_left);

    $(window).resize(function() {
	    var hero_nav_left = (windowWidth / 2) - ( $('#hero_nav').outerWidth() / 2 );
	    $('#hero_nav').css('left', hero_nav_left);
    });



    $('#hero .slides').touchwipe({
        wipeLeft: function() {
            $('#hero .slides').cycle('next');
        },
        wipeRight: function() {
            $('#hero .slides').cycle('prev');
        },
        min_move_x: 10,
        min_move_y: 100,
        preventDefaultEvents: false
    });






	$('#types_list .type:odd').addClass('even');
	$('#about_subnav li:odd').addClass('even');




	$('#project_list img').lazyload({
	    effect : 'fadeIn'
	});


    // $('#filter-toggle').on('click', function() {
    //     if ($('.filter-container').is(':visible')) {
    //         $('.filter-container').fadeOut();
    //     } else {
    //         $('.filter-container').fadeIn();
    //     }
    // });

    $('.filter input:checkbox').on('click', function () {
		var filter = $(this);
		var filter_value = $(filter).attr('value');
	    var filter_query = $('#project_list').attr('data-filters');

        if($(filter).is(':checked')) {
	        $('#project_list').attr('data-filters', filter_query += filter_value);
        } else {
        	filter_query = filter_query.replace(filter_value, '');
	        $('#project_list').attr('data-filters', filter_query);
        }

		if(filter_query === '') {
			$('.filter input:checkbox').prop("false", true);
			$('#all').prop("checked", true);
			$('#project_list > .project').show();
			$('#project_list img').lazyload();
		} else if(filter_value === '*') {
	       	$('#project_list').attr('data-filters', '');
			$('#project_list > .project').show();
			$('#project_list img').lazyload();
			$('.filter input').prop("checked", false);
			$(this).prop("checked", true);
		} else {
			$('#all').prop("checked", false);
		    $('#project_list > .project').hide();
			$('#project_list > .project').filter(filter_query).show();
			$('#project_list img').lazyload();
		}

    });
    $('#all').prop("checked", true);

    // $(":checkbox").on("change", function(){
    //     var checkboxValues = {};
    //     $(":checkbox").each(function(){
    //       checkboxValues[this.id] = this.checked;
    //     });
    //     $.cookie('checkboxValues', checkboxValues, { expires: 7, path: '/' })
    //   });

    //   function repopulateCheckboxes(){
    //     var checkboxValues = $.cookie('checkboxValues');
    //     if(checkboxValues){
    //       Object.keys(checkboxValues).forEach(function(element) {
    //         var checked = checkboxValues[element];
    //         $("#" + element).prop('checked', checked);
    //       });
    //     }
    //   }

    //   $.cookie.json = true;
    //   repopulateCheckboxes();



    //People Expand/Retract
    $('.people .expand').click( function() {
        $(this).parent().parent().find('.content').slideDown('slow');
        $(this).hide();
        $(this).parent().find('.retract').show();

       return false;
    });

    $('.people .retract').click( function() {
        $(this).parent().parent().find('.content').slideUp('fast');
        $(this).hide();
        $(this).parent().find('.expand').show();

       return false;
    });



    //Testimonials Expand/Retract

    $('.testimonial .expand').click( function() {

        var testimonial = $(this).parent('.testimonial');

        testimonial.children('.excerpt').hide();
        testimonial.children('.content').slideDown();

        testimonial.addClass('expanded');
        $(this).hide();
        testimonial.children('.retract').show();

       return false;
    });

    $('.testimonial .retract').click( function() {

        var testimonial = $(this).parent('.testimonial');


        testimonial.children('.content').slideUp('fast', function(){
            testimonial.children('.excerpt').delay(100).fadeIn();
        });

        testimonial.removeClass('expanded');
        $(this).hide();
        testimonial.children('.expand').show();

       return false;
    });

    //Jobs Expand/Retract

    $('.job .expand').click( function() {

        var job = $(this).parent('.job');

        job.children('.excerpt').hide();
        job.children('.content').slideDown();

        job.addClass('expanded');
        $(this).hide();
        job.children('.retract').show();

       return false;
    });

    $('.job .retract').click( function() {

        var job = $(this).parent('.job');


        job.children('.content').slideUp('fast', function(){
            job.children('.excerpt').delay(100).fadeIn();
        });

        job.removeClass('expanded');
        $(this).hide();
        job.children('.expand').show();

       return false;
    });


    $('.journal_share #share li a').each( function() {
        var label = $(this).html();
        $(this).append('<span class="label">' + label + '</span>');

    });

    $('#giving-back #article_content p img').removeAttr('height').removeAttr('width');





    if (parseInt($(window).width()) > 480) {


        // Photo Slideshow
		$('#process #slideshow').exists(function() {

	    	$('#process #slideshow').append('<div id="slideshow_nav">');
	        $('#process #slideshow').cycle({
	            fx:      'scrollHorz',
	            timeout:  0,
	            slideExpr: '.slide',
	            prev: '#prev-old',
	            next: '#next-old',
	            pager:  '#slideshow_nav',
	            speed: 400,
	            fit: true,
	            slideResize: true
	        });

        });




        var slideshow_width = $('#process #slideshow').width();
        var nav_left = (slideshow_width / 2) - ( $('#slideshow_nav').outerWidth() / 2 );
        $('#slideshow_nav').css('left', nav_left);
        $(window).resize(function() {
            var slideshow_width = $('#process #slideshow').width();
            var nav_left = (slideshow_width / 2) - ( $('#slideshow_nav').outerWidth() / 2 );
            $('#slideshow_nav').css('left', nav_left);
        });
    }

    $('.single-post article img').removeAttr('width').removeAttr('height');



});


$(function () {
    $('.people').each(function(){
        if($(this).parent().parent().hasClass("team-container")){
            $(this).parent().parent().addClass("title-margin");
        } 
    });
});

$(function () {
    $('.team-members').each(function(){
        if($(this).children().length === 0){
            $(this).parent().addClass("sub-only");

        } 
    });
});






